import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import AgoraRTC from "agora-rtc-sdk-ng";

import cameraIcon from "../Assets/Images/camera_icon.svg";
import videoCallIcon from "../Assets/Images/video_call.svg";
import micIcon from "../Assets/Images/mic_icon.svg";
import micOffIcon from "../Assets/Images/mic_off_icon.svg";
import otherUser from "../Assets/Images/other_user.svg";
import endCallIcon from "../Assets/Images/end_call.svg";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";

export default function Sharecall(props) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  
  const appId = "5da64dc39db9467485eeaac6d515f210";

  
  const [agoraFeatureFlag, setAgoraFeatureFlag] = useState(false);

 
  const client = useRef(null);
  const localAudioTrack = useRef(null);
  const localVideoTrack = useRef(null);
  const localPlayerRef = useRef(null);

  const [name, setName] = useState("");
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [err, setErr] = useState("");

 
  const [videoCall, setVideoCall] = useState(false);


  const [isJoined, setIsJoined] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);


  const flagFeature = async () => {
    try {
     
      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}agoraInfo/get`
      );
    
      if (response?.data[5]?.isAgora === true) {
        setAgoraFeatureFlag(true);
      } else {
        setAgoraFeatureFlag(false);
      }
    } catch (error) {
      console.error("Error fetching Agora vs Maggie plus feature flag:", error);
     
      setAgoraFeatureFlag(false);
    }
  };

 
  const launchMaggiePlus = () => {
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg", 
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "PARTICIPANT",
      meetingKey: params.id,
      memberName: location?.state?.name ? location?.state?.name : name,
      memberEmail: "test@gmail.com",
      memberMobile: "1234567890",
    };

    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        { headers: header }
      )
      .then((result) => {
        const maggieUrl = result.data?.response?.url;
        if (params.type === "A") {
         
          window.location.href = maggieUrl + "/1";
        } else {
         
          window.location.href = maggieUrl;
        }
        setName("");
      })
      .catch((err) => {
        console.log("Maggie plus error", err);
      });
  };


  const setupAgora = async () => {
    try {
      client.current = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      const channelId = params?.id ? String(params.id) : "defaultChannel";
      const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

     
      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
      );
      const token = response.data?.data?.token;
      const uuid = response.data?.data?.uid;

      if (!token || !appId || !sanitizedChannelId) {
        throw new Error("App ID, Token, or Channel ID is undefined");
      }

     
      await client.current.join(appId, sanitizedChannelId, token, uuid);
      setVideoCall(true);

     
      localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
      localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();

     
      if (!localPlayerRef.current) {
        console.error(
          "Local video DOM element not found. Will retry in 100ms..."
        );
        setTimeout(() => {
          if (localPlayerRef.current) {
            localVideoTrack.current.play(localPlayerRef.current);
          }
        }, 100);
      } else {
        localVideoTrack.current.play(localPlayerRef.current);
      }

     
      await client.current.publish([
        localAudioTrack.current,
        localVideoTrack.current,
      ]);
      console.log("Published local video + audio tracks to channel");

      setIsJoined(true);

      
      client.current.remoteUsers.forEach((user) => {
        handleExistingUser(user);
      });

    
      client.current.on("user-published", handleUserPublished);
      client.current.on("user-unpublished", handleUserUnpublished);
      client.current.on("user-left", handleUserLeft);
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  // Subscribe and track existing remote user
  const handleExistingUser = async (user) => {
    try {
      await client.current.subscribe(user, "video");
      await client.current.subscribe(user, "audio");
      setRemoteUsers((prevUsers) => {
        const existingUser = prevUsers.find((u) => u.uid === user.uid);
        if (!existingUser) {
          return [...prevUsers, { ...user, isCameraOff: !user.hasVideo }];
        }
        return prevUsers;
      });
      if (user.hasVideo) {
        user.videoTrack.play(`remote-player-${user.uid}`);
      }
      if (user.hasAudio) {
        user.audioTrack.play();
      }
    } catch (error) {
      console.error("Error subscribing to existing user:", error);
    }
  };

  // For newly published streams
  const handleUserPublished = async (user, mediaType) => {
    try {
      await client.current.subscribe(user, mediaType);
      setRemoteUsers((prevUsers) => {
        const existingUser = prevUsers.find((u) => u.uid === user.uid);
        if (!existingUser) {
          return [
            ...prevUsers,
            { ...user, isCameraOff: mediaType !== "video" },
          ];
        }
        return prevUsers.map((u) =>
          u.uid === user.uid
            ? { ...u, isCameraOff: mediaType !== "video" }
            : u
        );
      });
      if (mediaType === "video") {
        user.videoTrack.play(`remote-player-${user.uid}`);
      }
      if (mediaType === "audio") {
        user.audioTrack.play();
      }
    } catch (error) {
      console.error("Error handling user-published:", error);
    }
  };

  // For unpublished streams
  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "video") {
      setRemoteUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.uid === user.uid ? { ...u, isCameraOff: true } : u
        )
      );
    }
  };

  const handleUserLeft = (user) => {
    console.log("User left:", user);
    setRemoteUsers((prevUsers) =>
      prevUsers.filter((remoteUser) => remoteUser.uid !== user.uid)
    );
    const remotePlayerElement = document.getElementById(
      `remote-player-${user.uid}`
    );
    if (remotePlayerElement) {
      remotePlayerElement.parentNode.removeChild(remotePlayerElement);
    }
  };

  // —————————————————————————————————————————————
  // 4) Decide which flow to use on "save" or "join"
  // —————————————————————————————————————————————
  const save = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      setErr("Please enter a name");
      return;
    }

    // If the toggle says “use Agora”, do it
    // Otherwise, use Maggie plus
    if (agoraFeatureFlag) {
      setVideoCall(true);
      setupAgora();
    } else {
      launchMaggiePlus();
    }
  };

  // —————————————————————————————————————————————
  // 5) Lifecycle: check if we have location.state
  //    and also fetch the Agora vs Maggie plus flag
  // —————————————————————————————————————————————
  useEffect(() => {
    flagFeature(); // fetch the isAgora setting
  }, []);

  useEffect(() => {
    // If the user came with some location state
    if (location?.state?.name) {
      setName(location.state.name);
      // If you want to auto-join the call if name is present, you can do so here
      // e.g. if (agoraFeatureFlag) setupAgora();
    }
  }, [location?.state]);

  // —————————————————————————————————————————————
  // 6) UI Interactions: toggles, end call, etc.
  // —————————————————————————————————————————————
  const toggleMute = async () => {
    if (localAudioTrack.current) {
      await localAudioTrack.current.setEnabled(isMuted);
      setIsMuted(!isMuted);
    }
  };

  const toggleCamera = async () => {
    try {
      if (!agoraFeatureFlag) {
        // If Maggie plus flow, you’d rely on the Maggie plus UI for toggling
        console.log("Camera toggle not available in Maggie plus iframe.");
        return;
      }
      if (!client.current) return;
      if (isCameraOff) {
        // Re-initialize camera track
        if (!localVideoTrack.current) {
          localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();
        }
        // Slight delay to ensure DOM updates
        setTimeout(() => {
          if (localPlayerRef.current) {
            localPlayerRef.current.innerHTML = "";
            localVideoTrack.current.play(localPlayerRef.current);
          }
        }, 200);
        await client.current.publish([localVideoTrack.current]);
        console.log("Published local video track again");
      } else {
        // Turn camera off
        if (localVideoTrack.current) {
          await client.current.unpublish([localVideoTrack.current]);
          localVideoTrack.current.stop();
          localVideoTrack.current.close();
          localVideoTrack.current = null;
          console.log("Unpublished and closed local video track");
        }
      }
      setIsCameraOff(!isCameraOff);
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };

  const endconsultation = async () => {
    try {
      // If we used Agora, gracefully leave
      if (agoraFeatureFlag && client.current) {
        if (localAudioTrack.current) {
          localAudioTrack.current.close();
        }
        if (localVideoTrack.current) {
          localVideoTrack.current.stop();
          localVideoTrack.current.close();
        }
        await client.current.leave();
        console.log("Left the Agora channel");
      }
    } catch (error) {
      console.log("Error leaving Agora channel:", error);
    }
    setIsJoined(false);
    setVideoCall(false);
    history.push(APP_ROUTES.DASHBOARD); // Or wherever you want to go
  };

  // Helpers
  const getGridClass = () => {
    // Local user + remote users
    const totalUsers = remoteUsers.length + 1;
    if (totalUsers === 1) return "grid-cols-1";
    if (totalUsers === 2) return "grid-cols-2";
    if (totalUsers <= 4) return "grid-cols-2 grid-rows-2";
    return "grid-cols-3 grid-rows-2";
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* 
        If you want some form or input to let the user enter their name, 
        you can render it conditionally if not in a call. 
      */}
      {!videoCall && (
        <div className="m-auto">
          <h1 className="text-xl font-semibold">Join the Call</h1>
          <div className="mt-4">
            <input
              type="text"
              placeholder="Enter your name..."
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErr("");
              }}
              className="border p-2 mb-2"
            />
            {err && <p className="text-red-500 text-sm">{err}</p>}
          </div>
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded"
            onClick={save}
          >
            Join
          </button>
        </div>
      )}

      {/* ——————————————————————————————————————————————————————
          If using Agora => Renders the in-call video UI
          If using Maggie plus => We redirect away to the Maggie page
          so there's actually no in-call UI here for Maggie 
      —————————————————————————————————————————————————————— */}
      {videoCall && agoraFeatureFlag && (
        <>
          <div className={`grid ${getGridClass()} gap-2 flex-1`}>
            {/* Local Video */}
            <div className="relative bg-black">
              {isCameraOff ? (
                <div className="flex items-center justify-center w-full h-full bg-gray-800">
                  <img
                    src={otherUser}
                    alt="User Placeholder"
                    className="w-20 h-20 opacity-50"
                  />
                </div>
              ) : (
                <div
                  ref={localPlayerRef}
                  id="local-player"
                  className="w-full h-full"
                ></div>
              )}
            </div>

            {/* Remote Videos */}
            {remoteUsers.map((user) => (
              <div
                key={user.uid}
                className="relative bg-black w-full h-full"
              >
                {user.isCameraOff ? (
                  <div className="flex items-center justify-center w-full h-full bg-gray-800">
                    {/* Show a placeholder or their initials */}
                  </div>
                ) : (
                  <div
                    id={`remote-player-${user.uid}`}
                    className="w-full h-full"
                  ></div>
                )}
              </div>
            ))}
          </div>

          {/* Controls */}
          <div className="flex justify-center bg-black bg-opacity-75 p-4">
            <button
              onClick={toggleMute}
              className="text-white bg-white rounded-full p-3"
            >
              <img
                src={isMuted ? micOffIcon : micIcon}
                alt="Mic Icon"
                className="w-8 h-8"
              />
            </button>

            <button
              onClick={toggleCamera}
              className="text-white bg-white rounded-full p-3 mx-4"
            >
              <img
                src={isCameraOff ? cameraIcon : videoCallIcon}
                alt="Camera Icon"
                className="w-8 h-8"
              />
            </button>

            <button
              onClick={endconsultation}
              className="text-white bg-red-500 rounded-full p-3"
            >
              <img
                src={endCallIcon}
                alt="End Call Icon"
                className="w-8 h-8"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
