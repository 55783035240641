// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./styles/tailwind.generated.css"
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
        
import "../node_modules/primereact/resources/themes/lara-light-indigo/theme.css";    //theme
import "../node_modules/primereact/resources/primereact.min.css";                    //core css
import "../node_modules/primeicons/primeicons.css";                                  //icons

import "./styles/tailwind-ext.css";
import "./styles/prime-ext.css";
import "./styles/loader.css"; 

ReactDOM.render(
  <React.StrictMode>
      <PrimeReactProvider>
    <App />
    </PrimeReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
